import { reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { PretensionsRealisation } from '@/core/realisations/Pretensions.realisation'
import { FullPretensionT, PretensionTypeT } from '@/core/types/Pretensions.types'

import useVuelidate from '@vuelidate/core'

import { useMaska } from '@/common/composable/useMaska'
import { useProfile } from '@/modules/Profile/composable/useProfile'

import useNotifications from '@/common/composable/useNotifications'
const NOTIFICATIONS_GROUP = 'create-module'

import { required, requiredIf, decimal } from '@vuelidate/validators'

export default function ({ is_create, is_update }: { is_create: boolean; is_update: boolean }) {
    const pretensions = new PretensionsRealisation()
    const route = useRoute()
    const router = useRouter()
    const notifications = useNotifications()
    const { loadProfile, profileState } = useProfile()

    const info_state = reactive({
        is_loading: false,
        is_failed: false,
        direction: 'Введите номер накладной',
        delivery_date: 'Введите номер накладной',
    })

    const pretension_state = reactive({
        is_loading: false,
        is_failed: false,
        data: null as FullPretensionT | null,
    })

    const form_state = reactive({
        invoice_number: null as string | null,
        dop_invoice_number: null as string | null,
        client_phone: null as string | null,
        product_description: null as string | null,
        product_amount: null as string | null,
        client_comment: null as string | null,
        type_id: null as number | null,
        client_name: null as string | null,
        subdivision_id: 91 as number,
        //
        files: [] as File[],
        pretension_scan: [] as File[],
        pretension_act: [] as File[],
        cargo_photo: [] as File[],
        files_employee_add: [] as File[],
        //
        is_loading: false,
        is_deleting: false,
    })

    const profile_state = reactive({
        company_id: null as number | null,
    })

    const PretensionValidation = {
        invoice_number: { required: requiredIf(() => form_state.type_id !== 5) },
        client_phone: { required },
        product_description: { required },
        product_amount: { required, decimal },
        client_comment: { required },
        type_id: { required },
        client_name: { required },
        cargo_photo: { required: requiredIf(() => form_state.type_id === 4 && is_create) },
    }

    const form_state$ = useVuelidate(PretensionValidation, form_state)

    const data_state = reactive({
        types: {
            is_loading: false,
            list: [] as (PretensionTypeT & { value: number })[],
        },
        subdivisions: {
            is_loading: false,
            list: [] as any[],
        },
    })

    const pretension_id = Number(route.params.pretension_id)

    const loadInformationByInvoiceNumber = async (invoice_number: string) => {
        try {
            info_state.is_loading = true

            const response = await pretensions.getInvoiceScan(invoice_number)
            info_state.direction = response.data.direction
            info_state.delivery_date = response.data.delivery_date

            info_state.is_failed = false
        } catch (error) {
            info_state.is_failed = true

            notifications.failure(
                {
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Ошибка получения данных по номеру накладной',
                },
                error
            )
        } finally {
            info_state.is_loading = false
        }
    }

    const initializeForm = () => {
        if (!pretension_state.data) return

        form_state.invoice_number = pretension_state.data.invoice_number
        loadInformationByInvoiceNumber(pretension_state.data.invoice_number)

        form_state.client_phone = pretension_state.data.client_phone
        form_state.product_description = pretension_state.data.product_description
        form_state.product_amount = pretension_state.data.product_amount.toString()
        form_state.client_comment = pretension_state.data.client_comment
        form_state.type_id = pretension_state.data.type_id
        form_state.client_name = pretension_state.data.client_name
        form_state.dop_invoice_number = pretension_state.data.dop_invoice_number
    }

    const loadPretension = async () => {
        if (!pretension_id) return

        try {
            pretension_state.is_loading = true

            const response = await pretensions.getPretension(pretension_id)
            pretension_state.data = response.data

            if (is_update) {
                initializeForm()
            }

            pretension_state.is_failed = false
        } catch (error) {
            notifications.failure(
                {
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Ошибка получения данных претензии',
                },
                error
            )

            pretension_state.is_failed = true
        } finally {
            pretension_state.is_loading = false
        }
    }

    const resetInformation = () => {
        info_state.is_failed = false
        info_state.direction = 'Введите номер накладной'
        info_state.delivery_date = 'Введите номер накладной'
    }

    const loadTypes = async () => {
        try {
            data_state.types.is_loading = true
            const response = await pretensions.getTypes()
            data_state.types.list = response.data.map((type) => {
                return {
                    ...type,
                    value: type.id,
                }
            })
        } catch (error) {
            console.error(error)

            notifications.failure(
                {
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Ошибка получения списка типов претензий',
                },
                error
            )
        } finally {
            data_state.types.is_loading = false
        }
    }

    const loadSubdivisions = async () => {
        try {
            data_state.subdivisions.is_loading = true

            const response = await pretensions.getSubdivisions({ page: 1 })
            data_state.subdivisions.list = response.data.map((subdivision) => {
                return {
                    ...subdivision,
                    value: subdivision.id,
                }
            })
        } catch (error) {
            console.error(error)

            notifications.failure(
                {
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Ошибка получения списка подразделений',
                },
                error
            )
        } finally {
            data_state.subdivisions.is_loading = false
        }
    }

    const getFormData = (): FormData | null => {
        if (
            (!form_state.invoice_number && form_state.type_id !== 5) ||
            !form_state.client_phone ||
            !form_state.product_description ||
            !form_state.product_amount ||
            !form_state.client_comment ||
            !form_state.type_id ||
            !form_state.client_name
        ) {
            return null
        }

        const { unmaskNumbers } = useMaska()
        const form_data = new FormData()

        if (form_state.invoice_number) {
            form_data.append('invoice_number', form_state.invoice_number as string)
        }
        form_data.append('dop_invoice_number', form_state.dop_invoice_number || '')
        form_data.append('subdivision_id', form_state.subdivision_id.toString())
        form_data.append('client_phone', unmaskNumbers(form_state.client_phone))
        form_data.append('product_description', form_state.product_description)
        form_data.append('product_amount', unmaskNumbers(form_state.product_amount))
        form_data.append('client_comment', form_state.client_comment)
        form_data.append('type_id', form_state.type_id.toString())
        form_data.append('client_name', form_state.client_name)

        if (form_state.pretension_scan.length) {
            form_state.pretension_scan.forEach((file) => form_data.append('files[]', file))
        }

        if (form_state.pretension_act.length) {
            form_state.pretension_act.forEach((file) => form_data.append('files[]', file))
        }

        if (form_state.cargo_photo.length) {
            form_state.cargo_photo.forEach((file) => form_data.append('files[]', file))
        }

        if (form_state.files.length) {
            form_state.files.forEach((file) => form_data.append('files[]', file))
        }

        if (form_state.type_id === 5 && profile_state.company_id) {
            form_data.append('company_id', profile_state.company_id.toString())
        }

        return form_data
    }

    const createPretension = async () => {
        form_state$.value.$touch()

        if (form_state$.value.$invalid) {
            return
        }

        try {
            form_state.is_loading = true

            if (form_state.type_id === 5) {
                await loadProfile()
                if (profileState && profileState.data && profileState.data.company) {
                    profile_state.company_id = profileState.data.company.id
                }
            }

            const form_data = getFormData()

            if (!form_data) return

            await pretensions.createPretension(form_data)

            notifications.success({
                group: NOTIFICATIONS_GROUP,
                type: 'success',
                title: 'Претензия создана',
            })

            router.go(-1)
        } catch (error) {
            console.error(error)

            notifications.failure(
                {
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Ошибка создания претензии',
                },
                error
            )
        } finally {
            form_state.is_loading = false
        }
    }

    const updatePretension = async () => {
        if (!pretension_id) return

        form_state$.value.$touch()
        if (form_state$.value.$invalid) return

        try {
            form_state.is_loading = true

            const { unmaskNumbers } = useMaska()
            const params = {
                invoice_number: form_state.invoice_number,
                dop_invoice_number: form_state.dop_invoice_number,
                client_phone: form_state.client_phone,
                product_description: form_state.product_description,
                product_amount: form_state.product_amount
                    ? Number(unmaskNumbers(form_state.product_amount))
                    : null,
                client_comment: form_state.client_comment,
                type_id: form_state.type_id,
                client_name: form_state.client_name,
                subdivision_id: form_state.subdivision_id,
            }

            await pretensions.updatePretension(pretension_id, params)

            notifications.success({
                group: NOTIFICATIONS_GROUP,
                type: 'success',
                title: 'Претензия изменена',
            })

            await uploadFiles()

            router.go(-1)
        } catch (error) {
            console.error(error)

            notifications.failure(
                {
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Ошибка редактирования претензии',
                },
                error
            )
        } finally {
            form_state.is_loading = false
        }
    }

    const onSubmit = () => {
        if (form_state.is_loading) return

        if (is_create) {
            createPretension()
        }

        if (is_update) {
            updatePretension()
        }
    }

    const getFileTypeByUrl = (url: string): string => {
        const splitted_url = url.split('.')
        return splitted_url[splitted_url.length - 1]
    }

    const downloadFile = (url: string) => {
        const link = document.createElement('a')
        link.href = url
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    const uploadFiles = async () => {
        try {
            form_state.is_loading = true

            const form_data = new FormData()

            form_data.append('id', pretension_id.toString())
            form_data.append('type', '3')

            if (form_state.pretension_scan.length) {
                form_data.append('files[]', form_state.pretension_scan[0])
            }

            if (form_state.pretension_act.length) {
                form_data.append('files[]', form_state.pretension_act[0])
            }

            if (form_state.cargo_photo.length) {
                form_data.append('files[]', form_state.cargo_photo[0])
            }

            if (form_state.files_employee_add.length) {
                form_state.files_employee_add.forEach((file) => form_data.append('files[]', file))
            }

            await pretensions.uploadFiles(form_data)

            notifications.success({
                group: NOTIFICATIONS_GROUP,
                type: 'success',
                title: 'Файлы прикреплены',
            })

            // form_state.files = []
            form_state.pretension_scan = []
            form_state.pretension_act = []
            form_state.cargo_photo = []
            form_state.files_employee_add = []

            await loadPretension()
        } catch (error) {
            console.error(error)

            notifications.failure(
                {
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Ошибка прикрепления файлов',
                },
                error
            )
        } finally {
            form_state.is_loading = false
        }
    }

    const deleteFile = async (file_id: number) => {
        const answer = confirm('Вы действительно хотите удалить файл?')
        if (!answer) return

        try {
            form_state.is_deleting = true
            await pretensions.deleteFile(file_id)

            if (pretension_state.data) {
                const index = pretension_state.data.files.data.findIndex(
                    (file) => file.id === file_id
                )
                if (index > -1) {
                    pretension_state.data.files.data.splice(index, 1)
                }
            }

            notifications.success({
                group: NOTIFICATIONS_GROUP,
                type: 'success',
                title: 'Файл удален',
            })
        } catch (error) {
            console.error(error)

            notifications.failure(
                {
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Ошибка удаления файла',
                },
                error
            )
        } finally {
            form_state.is_deleting = false
        }
    }

    return {
        pretension_state,
        info_state,
        form_state,
        data_state,
        //
        form_state$,
        //
        pretension_id,
        //
        loadPretension,
        loadInformationByInvoiceNumber,
        resetInformation,
        createPretension,
        updatePretension,
        loadTypes,
        loadSubdivisions,
        onSubmit,
        getFileTypeByUrl,
        downloadFile,
        uploadFiles,
        deleteFile,
    }
}
